import React from "react";

const HomePageSvg = () => {
  return (
    <svg
      width="257"
      height="181"
      viewBox="0 0 257 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_92_1880)">
        <g clipPath="url(#clip1_92_1880)">
          <path
            d="M48.301 47.9915C42.853 44.254 35.5021 45.4341 31.4511 50.5275C31.4511 50.5275 32.2433 51.8028 32.9758 52.6755C33.0169 52.7265 33.0579 52.7408 33.0933 52.7724C35.0049 55.1487 37.6403 57.6129 40.8702 59.83C44.6678 62.4273 48.525 64.2041 51.778 65.0077C55.3751 59.3486 53.8734 51.8134 48.301 47.9915Z"
            fill="#3894F4"
          />
          <path
            d="M50.4956 66.6944C47.3013 65.8579 43.5543 64.1172 39.8643 61.583C35.5289 58.6178 32.1743 55.1825 30.3517 52.1398C28.7002 55.0419 28.3463 58.3787 29.1274 61.4315C30.7541 63.6232 32.9119 65.7282 35.5469 67.5258C37.6517 68.9638 39.8403 70.0413 41.9744 70.7884C42.294 70.7685 42.608 70.7293 42.9255 70.6839C42.9601 70.6948 42.9962 70.7105 43.0309 70.7214L43.0728 70.6672C45.8647 70.251 48.5148 68.8999 50.5018 66.6978L50.4956 66.6944Z"
            fill="#3894F4"
          />
          <path
            d="M27.4994 122.562C28.3269 125.397 35.5905 125.77 43.7312 123.393C51.8719 121.016 57.7891 116.796 56.9616 113.961C56.1906 111.32 49.8199 110.823 42.376 112.692L34.0219 84.076L36.8219 80.0197C37.058 79.6778 37.0075 79.1991 36.7583 78.705L41.1085 73.1621C38.8277 72.4159 36.4774 71.27 34.2218 69.7291C31.4019 67.8017 29.0977 65.519 27.4438 63.1621L23.3502 69.8899C23.0737 69.9496 22.8307 70.052 22.6906 70.2557L17.3 78.0603C17.0384 78.4411 17.1468 78.9921 17.4787 79.5357L14.9204 83.7366C13.3849 85.9748 13.8138 88.9362 15.8797 90.3488L20.0331 93.1939C22.1004 94.6114 25.0134 93.9447 26.5503 91.7112L29.4383 88.0283C29.6741 88.1169 29.9077 88.1799 30.1315 88.2091L37.7005 114.135C31.1839 116.573 26.7826 120.089 27.5042 122.561L27.4994 122.562Z"
            fill="#0450CC"
          />
          <path
            d="M58.5444 113.43C59.5654 116.928 53.2859 121.832 44.5164 124.392C35.7469 126.952 27.8138 126.192 26.7943 122.7C25.7747 119.207 32.0528 114.298 40.8223 111.738C49.5918 109.178 57.5249 109.938 58.5444 113.43Z"
            fill="#0450CC"
          />
        </g>
        <g clipPath="url(#clip2_92_1880)">
          <path
            d="M192.312 71.6732C196.907 62.9962 193.786 55.786 187.278 52.341C180.773 48.8949 177.388 52.6554 174.136 50.9323C170.883 49.2092 171.457 48.1255 169.288 46.9756C165.297 44.8612 161.816 47.7156 159.713 50.2377L166.947 54.0704C168.63 53.2853 170.64 53.2516 172.409 54.1858C175.403 55.7744 176.546 59.4856 174.957 62.4798C173.372 65.4729 169.657 66.6164 166.663 65.0279C164.897 64.0888 163.795 62.4095 163.501 60.5751L156.267 56.7425C155.358 59.8971 154.952 64.3813 158.944 66.4958C161.112 67.6457 161.689 66.5608 164.941 68.2838C168.194 70.0069 166.982 74.9197 173.487 78.3659C179.993 81.8156 187.713 80.3477 192.312 71.6732ZM183.508 72.5643L188.104 63.8909L190.272 65.0408L185.676 73.7142L183.508 72.5643Z"
            fill="#FA276E"
          />
          <path
            d="M128.972 33.9431L125.523 40.4507L135.281 45.6216L138.73 39.114L128.972 33.9431Z"
            fill="#FA276E"
          />
          <path
            d="M123.864 40.9582L122.714 43.1272L125.967 44.852L127.117 42.683L123.864 40.9582Z"
            fill="#FB4F78"
          />
          <path
            d="M128.203 43.2578L127.054 45.4272L130.308 47.1508L131.457 44.9814L128.203 43.2578Z"
            fill="#FB4F78"
          />
          <path
            d="M132.54 45.5565L131.39 47.7257L134.644 49.4499L135.793 47.2807L132.54 45.5565Z"
            fill="#FB4F78"
          />
          <path
            d="M129.611 30.1161L128.462 32.2855L131.716 34.0092L132.865 31.8398L129.611 30.1161Z"
            fill="#FB4F78"
          />
          <path
            d="M133.947 32.415L132.798 34.5842L136.051 36.3084L137.201 34.1392L133.947 32.415Z"
            fill="#FB4F78"
          />
          <path
            d="M138.284 34.7116L137.134 36.8809L140.388 38.6051L141.538 36.4359L138.284 34.7116Z"
            fill="#FB4F78"
          />
          <path
            d="M168.014 54.8354C167.59 54.9731 167.205 55.1696 166.852 55.4035L165.725 54.8085L158.947 51.2169L139.24 40.7744L136.94 45.1117L156.647 55.5542L163.426 59.1457L164.61 59.7734C164.628 60.2203 164.707 60.6711 164.852 61.1165C165.715 63.7232 168.525 65.1372 171.132 64.2743C173.739 63.4114 175.153 60.601 174.29 57.9943C173.427 55.3876 170.617 53.9737 168.01 54.8365L168.014 54.8354Z"
            fill="#FB4F78"
          />
        </g>
        <g clipPath="url(#clip3_92_1880)">
          <path
            d="M68.5416 73.4755L72.0356 73.1821L73.0691 85.4879L62.2934 86.3928L59.3402 51.2277L66.6219 50.6161L68.5416 73.4755ZM81.2467 49.3879L77.7527 49.6814L79.6725 72.5408L76.0351 72.8462L77.0685 85.152L87.8442 84.247L86.8107 71.9413L83.1665 72.2473L81.2467 49.3879ZM96.6537 48.094L92.3844 48.4526L94.3041 71.312L91.1517 71.5767L92.1852 83.8825L102.961 82.9775L101.927 70.6718L98.57 70.9537L96.6503 48.0943L96.6537 48.094ZM107.785 47.1593L109.704 70.0187L105.995 70.3302L107.029 82.6359L117.808 81.7307L114.854 46.5655L107.785 47.1593Z"
            fill="#11C8CA"
          />
          <path
            d="M75.3314 44.6845L68.3062 45.2745L70.4134 70.3665L77.4386 69.7765L75.3314 44.6845Z"
            fill="#0096A0"
          />
          <path
            d="M89.9459 43.4569L82.9139 44.0475L85.0211 69.1395L92.0532 68.5489L89.9459 43.4569Z"
            fill="#0096A0"
          />
          <path
            d="M105.295 42.1682L98.2697 42.7582L100.377 67.8502L107.402 67.2602L105.295 42.1682Z"
            fill="#0096A0"
          />
        </g>
        <g clipPath="url(#clip4_92_1880)">
          <path
            d="M131.245 102.994L109.778 121.764C108.857 122.57 108.857 123.879 109.778 124.684C110.242 125.085 110.84 125.29 111.445 125.29C112.049 125.29 112.654 125.09 113.111 124.684L131.239 108.83V102.994H131.245Z"
            fill="#FF6300"
          />
          <path
            d="M188.364 101.002C188.364 112.978 178.652 122.688 166.674 122.688C154.696 122.688 144.984 112.978 144.984 101.002C144.984 89.0269 154.696 79.3163 166.674 79.3163C178.652 79.3163 188.364 89.0269 188.364 101.002Z"
            fill="#FEBA00"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M125.572 69.2246C125.638 66.534 128.153 64.3701 131.245 64.3701C134.337 64.3701 136.853 66.5339 136.919 69.2246C140.972 69.3884 144.987 69.9961 148.868 71.0322L158.041 73.4805C158.422 73.5831 158.657 73.9116 158.598 74.2555C158.539 74.5994 158.205 74.8509 157.806 74.8509H104.684C104.285 74.8509 103.95 74.5994 103.892 74.2555C103.833 73.9116 104.068 73.5831 104.449 73.4805L113.622 71.0322C117.507 69.9962 121.522 69.3886 125.572 69.2246Z"
            fill="#FEBA00"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M158.041 79.1266C158.422 79.0239 158.657 78.6954 158.598 78.3516C158.54 78.0077 158.199 77.7562 157.806 77.7562H104.684C104.285 77.7562 103.95 78.0077 103.892 78.3516C103.833 78.6954 104.074 79.0239 104.449 79.1266L113.616 81.5749C117.542 82.6261 121.601 83.2325 125.697 83.3877C125.976 84.5277 126.702 85.5219 127.707 86.2239V106.083L109.778 121.764C108.857 122.57 108.857 123.878 109.778 124.684C110.236 125.09 110.841 125.29 111.445 125.29H111.451C112.055 125.29 112.654 125.085 113.118 124.684L127.707 111.924V116.462C127.707 118.171 129.291 119.557 131.246 119.557C133.2 119.557 134.784 118.171 134.784 116.462V111.924L149.373 124.684C150.295 125.49 151.791 125.49 152.713 124.684C153.634 123.878 153.634 122.57 152.713 121.764L134.784 106.083V86.2318C135.795 85.5292 136.526 84.5315 136.806 83.387C140.898 83.2299 144.952 82.6206 148.874 81.5749L158.041 79.1266Z"
            fill="#FEBA00"
          />
          <path
            d="M179.673 123.925L171.744 99.4467C172.167 98.7589 172.249 97.6041 171.885 96.3773C171.357 94.5809 170.072 93.3234 169.021 93.5595C167.971 93.7956 167.548 95.4432 168.082 97.2396C168.44 98.4663 169.156 99.4416 169.907 99.8624L176.551 124.628C176.551 124.628 176.562 124.674 176.568 124.689C176.809 125.439 177.707 125.875 178.564 125.664C179.42 125.454 179.919 124.669 179.679 123.919L179.673 123.925Z"
            fill="#FF6300"
          />
          <path
            d="M171.463 123.925L163.534 99.4467C163.957 98.7589 164.039 97.6041 163.675 96.3773C163.147 94.5809 161.862 93.3234 160.811 93.5595C159.761 93.7956 159.338 95.4432 159.872 97.2396C160.23 98.4663 160.946 99.4416 161.697 99.8624L168.341 124.628C168.341 124.628 168.352 124.674 168.358 124.689C168.599 125.439 169.497 125.875 170.353 125.664C171.21 125.454 171.709 124.669 171.469 123.919L171.463 123.925Z"
            fill="#FF6300"
          />
        </g>
        <g clipPath="url(#clip5_92_1880)">
          <path
            d="M212.388 74.4445C212.388 74.4445 210.031 76.4952 207.772 73.5624C205.513 70.6296 210.449 64.7509 207.643 64.2335C204.836 63.716 201.004 66.6676 199.322 75.6319C197.641 84.5962 194.691 86.4111 190.111 89.7009C185.531 92.9906 175.608 104.581 194.43 114.42C213.252 124.26 216.609 114.272 217.22 101.471C217.833 88.6652 224.772 94.5532 226.731 84.644C228.693 74.7294 222.498 82.063 222.498 82.063C222.498 82.063 218.732 87.8983 214.886 82.5307L212.388 74.4445Z"
            fill="#5731BD"
          />
          <path
            d="M248.107 20.475C245.896 19.3195 244.853 20.7 244.853 20.7C244.853 20.7 231.866 32.7551 232.494 33.3979C233.123 34.0407 232.851 34.8484 232.851 34.8484L207.508 83.3281L212.924 86.159L238.267 37.6792C239.362 34.7679 242.641 34.6963 242.641 34.6963C242.641 34.6963 245.065 26.896 245.425 25.981C245.783 25.0712 247.514 25.662 248.391 25.1774C249.262 24.6901 250.322 21.6332 248.112 20.4777L248.107 20.475Z"
            fill="#9F61D4"
          />
          <path
            d="M205.73 101.952L198.306 98.0705L195.997 102.487L203.421 106.369L205.73 101.952Z"
            fill="#9F61D4"
          />
        </g>
        <path
          d="M110.385 18.9907C110.203 17.9556 108.827 17.713 108.302 18.6235L106.077 22.4833L101.665 21.8604C100.625 21.7135 99.9687 22.9471 100.672 23.7277L103.656 27.0367L101.7 31.0398C101.239 31.9841 102.209 32.9891 103.169 32.561L107.238 30.7463L110.441 33.8432C111.196 34.5738 112.452 33.9613 112.342 32.9161L111.873 28.4856L115.808 26.3965C116.737 25.9037 116.542 24.5202 115.514 24.3023L111.155 23.3788L110.385 18.9907Z"
          fill="#FEBA00"
        />
        <path
          d="M200.271 42.531L200.059 30.3943C200.057 30.2843 200.127 30.1859 200.232 30.1521L208.686 27.4172C208.845 27.3655 209.01 27.4828 209.012 27.6507L209.172 36.8127C208.772 36.6971 208.348 36.6392 207.919 36.6466C205.982 36.6805 204.193 38.0447 204.227 39.9616C204.26 41.8785 206.096 43.1795 208.033 43.1457C209.97 43.1119 211.759 41.7476 211.725 39.8307L211.512 27.607C211.48 25.7606 209.673 24.4701 207.916 25.0385L199.462 27.7734C198.311 28.146 197.538 29.2277 197.559 30.4379L197.718 39.513C197.317 39.3974 196.893 39.3395 196.465 39.347C194.528 39.3808 192.738 40.7451 192.772 42.6619C192.805 44.5788 194.641 45.8798 196.578 45.846C198.515 45.8122 200.304 44.4479 200.271 42.531Z"
          fill="#3894F4"
        />
        <path
          d="M81.3843 96.741C80.9531 95.4185 79.0822 95.4185 78.651 96.741L77.0666 101.6C76.9479 101.963 76.6628 102.249 76.2991 102.367L71.4403 103.952C70.1179 104.383 70.1179 106.254 71.4403 106.685L76.3 108.27C76.6633 108.388 76.9482 108.673 77.0671 109.036L78.6515 113.877C79.0836 115.198 80.9517 115.198 81.3839 113.877L82.9686 109.035C83.0873 108.672 83.3717 108.388 83.7344 108.269L88.5764 106.684C89.8969 106.252 89.8969 104.384 88.5764 103.952L83.7354 102.368C83.3722 102.249 83.0875 101.964 82.9691 101.601L81.3843 96.741Z"
          fill="#9F61D4"
        />
        <path
          d="M89.0814 110.576C88.8921 109.864 87.8815 109.864 87.6922 110.576L87.3632 111.814C87.2528 112.229 86.9285 112.553 86.5132 112.664L85.2757 112.993C84.5635 113.182 84.5635 114.193 85.2757 114.382L86.5132 114.711C86.9285 114.821 87.2528 115.146 87.3632 115.561L87.6922 116.799C87.8815 117.511 88.8921 117.511 89.0814 116.799L89.4104 115.561C89.5208 115.146 89.8451 114.821 90.2604 114.711L91.4979 114.382C92.2101 114.193 92.2101 113.182 91.4979 112.993L90.2604 112.664C89.8451 112.553 89.5208 112.229 89.4104 111.814L89.0814 110.576Z"
          fill="#9F61D4"
        />
      </g>
      <defs>
        <clipPath id="clip0_92_1880">
          <rect
            width="255.601"
            height="181"
            fill="white"
            transform="translate(0.699341)"
          />
        </clipPath>
        <clipPath id="clip1_92_1880">
          <rect
            width="46.7814"
            height="76.8588"
            fill="white"
            transform="translate(4.81934 55.9143) rotate(-16.2748)"
          />
        </clipPath>
        <clipPath id="clip2_92_1880">
          <rect
            width="62.5333"
            height="63.7192"
            fill="white"
            transform="translate(119.548 33.3851) rotate(-18.0006)"
          />
        </clipPath>
        <clipPath id="clip3_92_1880">
          <rect
            width="55.7097"
            height="40.4713"
            fill="white"
            transform="translate(58.9068 46.0636) rotate(-4.80049)"
          />
        </clipPath>
        <clipPath id="clip4_92_1880">
          <rect
            width="86.8311"
            height="63.9175"
            fill="white"
            transform="translate(103.88 64.3701)"
          />
        </clipPath>
        <clipPath id="clip5_92_1880">
          <rect
            width="34.8491"
            height="108.129"
            fill="white"
            transform="translate(229.3 10.6436) rotate(27.5987)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HomePageSvg;
