import { useTheme } from "@emotion/react";
import React from "react";
const DrumsIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="48"
      height="38"
      viewBox="0 0 48 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6_1932)">
        <path
          d="M15.3879 22.2674L3.96434 32.2558C3.47404 32.6847 3.47404 33.3811 3.96434 33.81C4.21105 34.023 4.52959 34.1323 4.85124 34.1323C5.1729 34.1323 5.49456 34.0257 5.73815 33.81L15.3848 25.3729V22.2674H15.3879Z"
          fill="white"
        />
        <path
          d="M45.7832 21.2078C45.7832 27.5804 40.6148 32.7478 34.2409 32.7478C27.8671 32.7478 22.6987 27.5804 22.6987 21.2078C22.6987 14.8352 27.8671 9.66782 34.2409 9.66782C40.6148 9.66782 45.7832 14.8352 45.7832 21.2078Z"
          fill="#282827"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.4071 4.29749C20.5641 4.38468 22.7006 4.70806 24.7658 5.2594L29.6469 6.56224C29.8499 6.61687 29.9748 6.79168 29.9436 6.97467C29.9124 7.15767 29.7344 7.29151 29.522 7.29151H1.25351C1.04115 7.29151 0.863143 7.15767 0.831914 6.97467C0.800685 6.79168 0.925601 6.61687 1.12859 6.56224L6.00969 5.2594C8.07728 4.70814 10.2139 4.38477 12.3688 4.29752C12.404 2.86571 13.7424 1.7142 15.3879 1.7142C17.0335 1.7142 18.3719 2.86569 18.4071 4.29749Z"
          fill="#282827"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.647 9.56676C29.85 9.51213 29.9749 9.33733 29.9436 9.15433C29.9124 8.97133 29.7313 8.83749 29.522 8.83749H1.25354C1.04118 8.83749 0.863178 8.97133 0.831949 9.15433C0.80072 9.33733 0.928759 9.51213 1.12862 9.56676L6.0066 10.8696C8.09584 11.429 10.2556 11.7517 12.4353 11.8343C12.584 12.4409 12.9702 12.9699 13.5049 13.3435V23.9116L3.96437 32.2558C3.47408 32.6847 3.47408 33.3812 3.96437 33.81C4.20796 34.0257 4.52962 34.1323 4.85128 34.1323H4.8544C5.17606 34.1323 5.4946 34.023 5.7413 33.81L13.5049 27.0199V29.4344C13.5049 30.344 14.3481 31.0814 15.388 31.0814C16.4279 31.0814 17.2711 30.344 17.2711 29.4344V27.0199L25.0346 33.81C25.5249 34.2388 26.3212 34.2388 26.8115 33.81C27.3018 33.3812 27.3018 32.6847 26.8115 32.2558L17.2711 23.9117V13.3478C17.809 12.9739 18.1977 12.443 18.3469 11.8339C20.5244 11.7503 22.6819 11.4261 24.769 10.8696L29.647 9.56676Z"
          fill="#282827"
        />
        <path
          d="M41.1581 33.4057L36.9391 20.38C37.1639 20.014 37.2077 19.3995 37.014 18.7467C36.733 17.7907 36.0491 17.1216 35.4901 17.2472C34.9311 17.3729 34.7062 18.2496 34.9904 19.2056C35.1809 19.8584 35.5619 20.3773 35.9616 20.6013L39.4968 33.7799C39.4968 33.7799 39.503 33.8045 39.5061 33.8127C39.6342 34.2115 40.112 34.4436 40.5679 34.3316C41.0239 34.2196 41.2893 33.8018 41.1613 33.403L41.1581 33.4057Z"
          fill="white"
        />
        <path
          d="M36.7892 33.4057L32.5702 20.38C32.7951 20.014 32.8388 19.3995 32.6452 18.7467C32.3641 17.7907 31.6802 17.1216 31.1212 17.2472C30.5622 17.3729 30.3373 18.2496 30.6215 19.2056C30.812 19.8584 31.193 20.3773 31.5927 20.6013L35.1279 33.7799C35.1279 33.7799 35.1341 33.8045 35.1372 33.8127C35.2653 34.2115 35.7431 34.4436 36.199 34.3316C36.655 34.2196 36.9204 33.8018 36.7924 33.403L36.7892 33.4057Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6_1932">
          <rect
            width="46.2065"
            height="34.0132"
            fill="white"
            transform="translate(0.825867 1.7142)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DrumsIcon;
