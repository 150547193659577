import { useTheme } from "@emotion/react";
import React from "react";
const VoiceIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="22"
      height="38"
      viewBox="0 0 22 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6_1928)">
        <path
          d="M20.2058 3.74113C18.3549 1.47775 15.0859 1.06745 12.7333 2.72875C12.7333 2.72875 12.9117 3.36874 13.1146 3.83033C13.1257 3.85709 13.1414 3.86824 13.1525 3.88608C13.6698 5.13261 14.4838 6.50625 15.5809 7.84867C16.872 9.423 18.2903 10.6562 19.5725 11.401C21.8024 9.44307 22.099 6.0558 20.2058 3.74113Z"
          fill="white"
        />
        <path
          d="M18.8186 11.9584C17.5654 11.2069 16.1895 10.0028 14.9363 8.46857C13.4623 6.67124 12.4633 4.79587 12.0664 3.27728C11.0049 4.30528 10.4407 5.67892 10.3939 7.07263C10.8131 8.20543 11.4687 9.36723 12.3652 10.4577C13.081 11.3296 13.8771 12.0588 14.691 12.6408C14.8293 12.672 14.9675 12.6943 15.108 12.7144C15.1214 12.7233 15.1348 12.7344 15.1481 12.7433L15.1727 12.7255C16.4103 12.895 17.7036 12.6497 18.8208 11.9606L18.8186 11.9584Z"
          fill="white"
        />
        <path
          d="M2.12082 32.8395C2.12082 34.1463 5.16022 35.2055 8.91319 35.2055C12.6662 35.2055 15.7033 34.1463 15.7033 32.8395C15.7033 31.622 13.0586 30.6208 9.66468 30.4914V17.2991L11.3572 15.9233C11.4999 15.8073 11.5378 15.5977 11.4932 15.3569L14.0287 13.5417C13.1523 12.9419 12.296 12.1636 11.5289 11.2293C10.57 10.0608 9.8743 8.80533 9.46399 7.59894L6.89065 9.94929C6.76577 9.94037 6.64981 9.95375 6.56508 10.0229L3.30715 12.6698C3.14882 12.7991 3.12652 13.0467 3.20011 13.3187L1.59233 14.786C0.662448 15.5464 0.477364 16.8576 1.17979 17.7139L2.59134 19.4376C3.29377 20.2962 4.61389 20.3742 5.54377 19.616L7.22737 18.4096C7.31656 18.4765 7.40799 18.5323 7.49942 18.5724V30.5249C4.4288 30.7523 2.12305 31.7 2.12305 32.8395H2.12082Z"
          fill="#282827"
        />
        <path
          d="M16.4417 32.8104C16.4417 34.4227 13.1659 35.7272 9.12302 35.7272C5.08015 35.7272 1.80438 34.4204 1.80438 32.8104C1.80438 31.2004 5.08015 29.8937 9.12302 29.8937C13.1659 29.8937 16.4417 31.2004 16.4417 32.8104Z"
          fill="#282827"
        />
      </g>
      <defs>
        <clipPath id="clip0_6_1928">
          <rect
            width="20.7027"
            height="34.0132"
            fill="white"
            transform="translate(0.751892 1.7142)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VoiceIcon;
